"use client";
// import "swiper/css";
import {
  Arrow,
  CardContent,
  CardDesc,
  CardImg,
  CardTitle,
  CategoryBtn,
  CommonCard,
  Stack,
} from "./molecules";
import dynamic from "next/dynamic";
const GlobalCourosel = dynamic(() => import("./Caurosel/globalCourosel"));
import Link from "next/link";
import React from "react";
import { getImageUrlNew, getLink } from "../lib/util";
import { useParams } from "next/navigation";
const DownloadBrochure = dynamic(() => import("./modal/DownloadBrochure"));
import { useDictionary } from "../context/dictionaryProvider";
import Image from "next/image";
import { Autoplay, Navigation } from "swiper/modules";
const StarRating = dynamic(() => import("./StarRating"));

const Element = ({ data }) => {
  const dict = useDictionary();
  const params = useParams();
  const rating = 4.5;

  const WrapImgLeft = ({ className }) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="71"
        height="72"
        viewBox="0 0 71 72"
        fill="none"
        className="z-10 absolute -right-[1px] top-0 bottom-auto"
      >
        <path
          d="M13.7419 10.548C13.4802 1.96949 4.47158 -0.725047 0 -1H71V72C71 68.654 70.104 65.9521 68.8363 63.8358C65.7667 58.7115 59.1067 57.9774 53.1334 57.9774H37.7419C24.4871 57.9774 13.7419 47.2322 13.7419 33.9774V10.548Z"
          fill="#fff"
        />
      </svg>
    );
  };
  return (
    <CommonCard>
      <CardImg>
        <Link
          className="relative block lg:hidden"
          href={getLink(`/${params?.lang}/${data?.slug}`)}
        >
          <WrapImgLeft />
          <Arrow classNames="left-auto top-0" />
        </Link>
        <div className="relative w-full h-[290px] overflow-hidden">
          {data?.thumbnail ? (
            <Image
              src={getImageUrlNew(data?.thumbnail, "small")}
              alt=""
              className="w-full rounded-[10px] h-full"
              fill
              objectFit="cover"
              objectPosition="center"
              loading="lazy"
            />
          ) : (
            <Image
              src="https://d3v381xb6gm5e.cloudfront.net/media/13969942_de24873a03.png"
              alt=""
              className="w-full rounded-[10px] h-full object-contain bg-[#0000001a] p-12"
              fill
              loading="lazy"
            />
          )}
        </div>
        {data?.category?.name ? (
          <CategoryBtn>{data?.category?.name}</CategoryBtn>
        ) : (
          ""
        )}
      </CardImg>
      <CardContent>
        <Link prefetch={false} href={getLink(`/${params?.lang}/${data?.slug}`)}>
          <CardTitle>{data?.course_name}</CardTitle>
          {data?.affiliations?.data?.length > 0 && (
            <div className="flex items-center gap-2 text-sm">
              <span>{dict?.courses.accreditedBy} </span>
              <div className="flex items-center gap-3">
                {data?.affiliations?.data?.slice(0, 2).map((aff, index) => (
                  <React.Fragment key={index}>
                    <div className="relative h-[29px] w-[36px]">
                      <Image
                        loading="lazy"
                        fill
                        alt=""
                        src={getImageUrlNew(aff.logo, "thumbnail")}
                        className="w-full h-full object-contain"
                      />
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          )}
          <CardDesc className="demandCardDesc">
            {data?.short_description}
          </CardDesc>
          <Stack className="gap-5">
            {data?.rating && (
              <>
                <StarRating rating={rating} />
                <p>{data?.rating}/5</p>
              </>
            )}
          </Stack>
        </Link>
        {data?.brochure?.url && (
          <DownloadBrochure
            courseId={data?.id}
            document={data?.brochure}
            type="standard"
            course={data}
          />
        )}
      </CardContent>
    </CommonCard>
  );
};

export default function DemandCarousel({ courses = [] }) {
  return (
    <GlobalCourosel
      data={courses}
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      navigation={true}
      speed={1500}
      pagination={{
        clickable: true,
      }}
      modules={[Navigation, Autoplay]} // Add this prop
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      }}
      className="w-full demandCourses "
      style={{ position: "unset" }}
      element={<Element />}
    />
  );
}
