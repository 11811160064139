"use client";
import React from "react";
import {
  BgImg2Wrap,
  ContentContainer,
  Layout,
  Stack,
  AccreContainer,
  AccreOverImg,
  PrimaryBtn,
  AccreOverImgBig,
  SecondaryBtn,
} from ".";
import data from "../../assets/common.json";
import dynamic from "next/dynamic";
import { useDictionary } from "../../context/dictionaryProvider";
import { useDisclosure } from "@nextui-org/react";
import { useRouter } from "next/navigation";
const EnquireModal = dynamic(() => import("../modal/EnquireModal"));

const RightEnrollWrapper = ({
  children,
  shape,
  btnText,
  btnStyle,
  bgColorValue,
  showBtn = true,
  isEnquire,
  course,
  ratingClass,
  enquireCss,
  redirectUrl,
}) => {
  const { isOpen, onOpenChange } = useDisclosure();
  const dict = useDictionary();
  const router = useRouter();

  return (
    <>
      <Layout
        className={`${
          ratingClass ? ratingClass : "mt-20"
        } accreditations mb-[8.5rem] mx-0`}
      >
        {!shape ? <BgImg2Wrap /> : shape}
        <ContentContainer>
          <Stack>
            <AccreContainer
              className={`px-10 md:px-20 pt-20 pb-28 z-0 ${
                bgColorValue ? bgColorValue : ""
              }`}
              style={{ width: "100%" }}
            >
              {isEnquire ? (
                <>
                  <AccreOverImgBig />
                  <SecondaryBtn
                    className={`${
                      enquireCss ? enquireCss : "right-0"
                    } !bg-[#316BCB] viewAllBtn`}
                    onClick={(e) => {
                      e.stopPropagation();
                      onOpenChange();
                    }}
                  >
                    {dict?.enquireNow}
                  </SecondaryBtn>
                </>
              ) : (
                showBtn && (
                  <>
                    <AccreOverImg className={``} accreImg />
                    <PrimaryBtn
                      className="viewAllBtn"
                      style={btnStyle}
                      onClick={() => redirectUrl && router.push(redirectUrl)}
                    >
                      {btnText || data.viewAll}
                    </PrimaryBtn>
                  </>
                )
              )}

              {children}
            </AccreContainer>
          </Stack>
        </ContentContainer>
        {isOpen && (
          <EnquireModal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            course={course}
          />
        )}
      </Layout>
    </>
  );
};

export default RightEnrollWrapper;
